import { useState } from 'react';
import pencilIcon from '../../assets/icons/pencilIcon';
import s from './Products.module.scss';
import Popup from '../../components/Popup/Popup';
import Input from '../../components/Input/Input';
import delIcon from '../../assets/icons/delIcon';
import ellipsisIcon from "../../assets/icons/ellipsis";

const ProductItem = ({
  product,
  activeProduct,
  setActiveProduct,
  delMaster,
  changeCategoryHandler,
  deleteHandler,
}) => {
  const [popupEditShowed, setPopupEditShowed] = useState(false);

  return (
    <li className={s.catWrap}>
      <div
        className={
          s.cat +
          ' ' +
          s.main +
          ' ' +
          (product._id === activeProduct._id ? s.active : '')
        }
      >
        <div className={s.catNameWrap}>
          <h3 className={s.catName}>
            {product.title}
            {product.slaves_count >= 0 && (
              <span className={s.catNumb}>
                {' (' + product.slaves_count + ') '}{' '}
              </span>
            )}
          </h3>
          <ul className={s.catStickers}>
            {product._id && (
              <li className={s.sticker + ' ' + s.blue}>id: {product._id}</li>
            )}
            {product.bxid && (
              <li className={s.sticker + ' ' + s.violet}>
                bx id: {product.bxid}
              </li>
            )}
            {product.api_name && (
              <li className={s.sticker + ' ' + s.yellow}>
                api: {product.api_name}
              </li>
            )}
          </ul>
        </div>
        <div className={s.btns}>
          <div className={s.moreActions}>
            <button className={s.moreActionsToggle}>
              {ellipsisIcon}
            </button>
            <div className={s.moreActionsDropdown}>
              <button onClick={() => changeCategoryHandler(product)}>Изменить категорию</button>
              <button onClick={(e) => {
                e.stopPropagation();
                delMaster(product);
              }}>Удалить</button>
            </div>
          </div>
          {/*<button*/}
          {/*  className={s.btnEdit}*/}
          {/*  onClick={(e) => {*/}
          {/*    e.stopPropagation();*/}
          {/*    delMaster(product);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {delIcon}*/}
          {/*</button>*/}
          {/*<button*/}
          {/*  className={s.btnEdit}*/}
          {/*  onClick={(e) => {*/}
          {/*    e.stopPropagation();*/}
          {/*    setPopupEditShowed(true);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {pencilIcon}*/}
          {/*</button>*/}
          <button
            className={'btn light small ' + s.btn}
            onClick={(e) => {
              e.stopPropagation();
              activeProduct._id === product._id
                ? setActiveProduct({})
                : setActiveProduct(product);
            }}
          >
            {activeProduct._id === product._id ? 'Отмена' : 'Соотнести'}
          </button>
          <button
            className={'btn light small ' + s.btn}
            onClick={(e) => {
              e.stopPropagation();
              setPopupEditShowed(true);
            }}
          >
            Изменить
          </button>
        </div>
      </div>

      {popupEditShowed && (
        <Popup
          //   className={s.popup}
          windowClassName={s.popup}
          close={() => setPopupEditShowed(false)}
          title={`Изменение названия ${product.title}`}
        >
          <Input
            placeholder=""
            val={product.title}
            className={s.popupInput}
            label={'Название товара'}
            setVal={() => console.log()}
          />
          <div className={s.btns}>
            <button
              className="btn light"
              onClick={() => setPopupEditShowed(false)}
            >
              Отмена
            </button>
            <button
              className="btn dark"
              onClick={() => setPopupEditShowed(false)}
            >
              Сохранить
            </button>
          </div>
        </Popup>
      )}
    </li>
  );
};

export default ProductItem;
