import api from '../../api/index.js';
import s from './Categories.module.scss';
import {useCallback, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import CategoryList from './CategoryList.jsx';
import Header from '../../components/Header/Header.jsx';

const Category = () => {
  const { cat_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [categories, setCategories] = useState([]);
  const [subLoading, setSubLoading] = useState(false);
  const [rootCategory, setRootCategory] = useState({
    category_name: '',
    _id: ''
  });

  const [subCategories, setSubCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState({});
  const [categoryLvl, setCategoryLvl] = useState('Все категории');
  const [startSubCategories, setStartSubCategories] = useState([]);
  const [subSortedCategories, setSubSortedCategories] = useState([]);
  const [loadedCategoryIdSlaves, SetLoadedCategoryIdSlaves] = useState();

  const getCategories = async () => {
    if (!cat_id) return;
    setLoading(true);
    const data = await api.categories.getFirstSubList(cat_id);
    if (data.categories) {
      setCategories(data.categories);
    }
    setLoading(false);
  };

  const deleteCategory = useCallback(async (category_id) => {
    const res = await api.categories.deleteLocalCategory(category_id);
    if (res.deleted){
      setCategories(prev => prev.filter(el => el._id !== category_id));
    } else{
      alert(res.error);
    }
  }, []);

  const renameCategory = useCallback(async (category_id, new_name) => {
    const res = await api.categories.editLocalCategory(category_id, {category_name: new_name});
    if (!res?.error){
      setCategories(prev => prev.map(category =>
        category.id === category_id ? { ...category, category_name: new_name } : category
      ));
    } else{
      alert(res.error);
    }
  }, []);

  const createCategory = useCallback(async (data) => {
    const res = await api.categories.createLocalCategory(data);
    if (!res?.error){
      setCategories(prev => [
        {
          _id: res.message.new_cat_id,
          api_category_id: res.message.new_cat_id,
          api_name: 'local',
          api_parent_category_id: data.parent_category_id,
          parent_category_id: data.parent_category_id,
          category_name: data.category_name,
          master: true,
          slaves: []
        },
        ...prev,
      ]);
    } else{
      alert(res.error);
    }
  }, []);

  const sortSubCategories = (val) => {
    setSubSortedCategories([
      ...subCategories.filter((cat) =>
        cat.category_name.toLowerCase().includes(searchVal.toLowerCase())
      ),
    ]);
  };

  useEffect(() => {
    if (searchVal) {
      sortSubCategories(searchVal);
    } else {
      setSubSortedCategories([...subCategories]);
    }
  }, [searchVal, subCategories]);

  const getCategorySlaves = async (subcategory_id, subCats) => {
    if (!subcategory_id) return;
    setSubLoading(true);
    const data = await api.categories.getSlavesList(subcategory_id);
    // console.log(cat_id);
    // console.log(subcategory_id);
    if (data.categories) {
      const categories = [];
      for (let catArr of data.categories) {
        if (Array.isArray(catArr)) {
          for (let cat of catArr) {
            categories.push({ ...cat, has_master: true });
          }
        } else {
          categories.push({ ...catArr, has_master: true });
        }
      }
      if (categoryLvl === 'Все категории') {
        if (subCats) {
          setSubCategories([...categories, ...subCats]);
        } else {
          setSubCategories([...categories, ...startSubCategories]);
        }
      } else {
        setSubCategories([...categories]);
      }
      SetLoadedCategoryIdSlaves(subcategory_id);
    }
    setTimeout(() => {
      setSubLoading(false);
    }, 500);
    return data.categories;
  };

  useEffect(() => {
    if (activeCategory.api_category_id) {
      getCategorySlaves(activeCategory.api_category_id);
    }
    // eslint-disable-next-line
  }, [categoryLvl]);

  useEffect(() => {
    if (activeCategory.api_category_id) {
      if (
        loadedCategoryIdSlaves !== activeCategory.api_category_id &&
        !subLoading
      ) {
        getCategorySlaves(activeCategory.api_category_id);
      }
    } else {
      SetLoadedCategoryIdSlaves();
      setSubCategories([]);
    }
    // eslint-disable-next-line
  }, [activeCategory, subLoading, loadedCategoryIdSlaves]);

  const getSubCategories = async () => {
    setSubLoading(true);
    const categories = [];
    const data = await api.categories.getAllSubList(
      activeCategory.api_category_id
    );
    if (data.categories) {
      // data.categories - массив массивов категорий с разных апи
      const apiCategoriesArr = data.categories;
      for (let catArr of apiCategoriesArr) {
        if (Array.isArray(catArr)) {
          for (let cat of catArr) {
            categories.push(cat);
          }
        } else {
          categories.push(catArr);
        }
      }
      setStartSubCategories(categories);
    }
    setSubLoading(false);
    return categories;
  };

  const getRootCategyName = async () => {
    if (!cat_id) return;
    const data = await api.categories.getCategoryById(cat_id);
    if (data?.category) {
      setRootCategory(data.category);
    }
  };

  useEffect(() => {
    getCategories();
    getSubCategories();
    getRootCategyName();
    // eslint-disable-next-line
  }, [cat_id]);

  const bindCategory = async (subCat) => {
    // compare
    if (!activeCategory || !subCat) return;
    setSubLoading(true);
    if (subCat.has_master) {
      return unBindCategory(subCat);
    }
    const body = {
      master_id: activeCategory.api_category_id,
      compared_api_name: subCat.api_name,
      compared_id: subCat._id,
      // sub_cat: true,
    };
    const data = await api.categories.compare(body);
    if (data.error) {
      setSubLoading(false);
      return alert(data.error);
    }
    const subCats = await getSubCategories();
    const slaves = await getCategorySlaves(
      activeCategory.api_category_id,
      subCats
    );
    setSubLoading(false);

    const editCategoryIndex = categories.findIndex(
      (i) => i.api_category_id === activeCategory.api_category_id
    );
    // const editSubCategoryIndex = subCategories.findIndex(
    //   (i) => i.api_category_id === subCat.api_category_id
    // );
    // const editStartSubCategoryIndex = startSubCategories.findIndex(
    //   (i) => i.api_category_id === subCat.api_category_id
    // );

    if (!slaves) return;
    const editCategory = {
      ...activeCategory,
      slaves: [...slaves],
    };
    // const editSubCategory = {
    //   ...subCat,
    //   has_master: true,
    // };
    setActiveCategory(editCategory);
    setCategories((prev) => [
      ...prev.slice(0, editCategoryIndex),
      editCategory,
      ...prev.slice(editCategoryIndex + 1),
    ]);
    // setSubCategories((prev) => [
    //   ...prev.slice(0, editSubCategoryIndex),
    //   editSubCategory,
    //   ...prev.slice(editSubCategoryIndex + 1),
    // ]);
    // setStartSubCategories((prev) => [
    //   ...prev.slice(0, editStartSubCategoryIndex),
    //   ...prev.slice(editStartSubCategoryIndex + 1),
    // ]);
  };

  const unBindCategory = async (subCat) => {
    // compare
    const body = {
      master_id: activeCategory.api_category_id,
      compared_api_name: subCat.api_name,
      compared_id: subCat._id,
      // sub_cat: true,
    };
    const data = await api.categories.deleteCompare(body);
    if (data.error) {
      setSubLoading(false);
      return alert(data.error);
    }
    const subCats = await getSubCategories();
    const slaves = await getCategorySlaves(
      activeCategory.api_category_id,
      subCats
    );
    const editCategoryIndex = categories.findIndex(
      (i) => i.api_category_id === activeCategory.api_category_id
    );
    const editCategory = {
      ...activeCategory,
      slaves: [...slaves],
    };
    setActiveCategory(editCategory);
    setCategories((prev) => [
      ...prev.slice(0, editCategoryIndex),
      editCategory,
      ...prev.slice(editCategoryIndex + 1),
    ]);

    // const editCategoryIndex = categories.findIndex(
    //   (i) => i.api_category_id === activeCategory.api_category_id
    // );
    // const editSubCategoryIndex = subCategories.findIndex(
    //   (i) => i.api_category_id === subCat.api_category_id
    // );
    // const editSlaveIndex = activeCategory.slaves.findIndex(
    //   (slave) => slave[subCat.api_name] === subCat.api_category_id
    // );
    // const editedSlaves = [
    //   ...activeCategory.slaves.slice(0, editSlaveIndex),
    //   ...activeCategory.slaves.slice(editSlaveIndex + 1),
    // ];

    // const editCategory = {
    //   ...activeCategory,
    //   slaves: [...slaves],
    // };

    // const editSubCategory = {
    //   ...subCat,
    //   has_master: false,
    // };

    // setActiveCategory(editCategory);
    // setCategories((prev) => [
    //   ...prev.slice(0, editCategoryIndex),
    //   editCategory,
    //   ...prev.slice(editCategoryIndex + 1),
    // ]);
    // setSubCategories((prev) => [
    //   ...prev.slice(0, editSubCategoryIndex),
    //   editSubCategory,
    //   ...prev.slice(editSubCategoryIndex + 1),
    // ]);
    // setStartSubCategories((prev) => [editSubCategory, ...prev]);
  };

  return (
    <div className={s.page}>
      <Header
        btnBack={{ name: 'назад к категориям', link: '/categories' }}
        title={`Категория: ${rootCategory.category_name}`}
        breadCrumbs={[
          { name: 'Главная', link: '/' },
          { name: 'Категории', link: '/categories' },
          {
            name: `Категория: ${rootCategory.category_name}`,
            link: '/categories',
          },
        ]}
      />

      <CategoryList
        loading={loading}
        isDetailPage={true}
        searchVal={searchVal}
        categories={categories}
        subLoading={subLoading}
        categoryLvl={categoryLvl}
        setSearchVal={setSearchVal}
        bindCategory={bindCategory}
        setCategoryLvl={setCategoryLvl}
        activeCategory={activeCategory}
        subCategories={subSortedCategories}
        setActiveCategory={setActiveCategory}
        catLvls={['Все категории', 'Сопоставленные']}
        deleteCategory={deleteCategory}
        renameCategory={renameCategory}
        createCategory={createCategory}
        rootCategory={rootCategory}
      />
    </div>
  );
};

export default Category;
