// import api from '../../api/index.js';
import Loader from '../../components/Loader/Loader';
import Search from '../../components/Search/Search';
import Select from '../../components/Select/Select';
import s from './Categories.module.scss';
import CategoryItem from './CategoryItem';
import SubCategoryItem from './SubCategoryItem';
import {useCallback, useEffect, useState} from "react";
import Popup from "../../components/Popup/Popup";
import {Link} from "react-router-dom";
import {createLocalCategory, deleteLocalCategory} from "../../api/categories";
import api from "../../api";
import Input from "../../components/Input/Input";
// import arrowIcon from '../../assets/icons/arrow.js';
// import Loader from '../../components/Loader/Loader.jsx';

const CategoryList = ({
  catLvls,
  loading,
  searchVal,
  subLoading,
  categories,
  categoryLvl,
  isDetailPage,
  setSearchVal,
  bindCategory,
  subCategories,
  activeCategory,
  setCategoryLvl,
  setActiveCategory,
  deleteCategory,
  renameCategory,
  createCategory,
  rootCategory,
}) => {
  const [categoryPopupData, setCategoryPopupData] = useState(null);

  const [activePopup, setActivePopup] = useState(null);

  const categoryPopupDataChangeName = useCallback((value) => {
    setCategoryPopupData(prev => ({
      ...prev,
      category_name: value
    }))
  }, []);

  useEffect(() => {
    setActivePopup(null);
  }, [categories]);

  const openRenamePopup = useCallback((category) => {
    setCategoryPopupData(category);
    setActivePopup('rename');
  }, []);

  const deleteCategoryHandler = useCallback(async (category) => {
    if (category.slaves.length > 0) {
      setCategoryPopupData(category);
      setActivePopup('delete');
    } else{
      deleteCategory(category._id);
    }
  }, [])

  const openCreatePopup = useCallback(() => {
    setCategoryPopupData({
      category_name: '',
      parent_category_id: rootCategory?._id,
      parent_category_name: rootCategory?.category_name,
    });
    setActivePopup('create');
  }, [rootCategory]);

  const closePopup = () => setActivePopup(null);

  // const [similarShowed, setSimilarShowed] = useState(false);

  // const getPercentClass = (percent) => {
  //   if (percent < 50) return s.red;
  //   else if (percent < 75) return s.yellow;
  //   else return s.green;
  // };

  return (
    <div className={s.categoriesWrap}>
      <div className={s.categories}>
        <button
          className={'btn light'}
          onClick={openCreatePopup}
        >
          Создать категорию
        </button>
        {!loading ? (
          <ul className={s.itemsList}>
            {categories.map((category, i) => (
              <CategoryItem
                key={i}
                category={category}
                isDetailPage={isDetailPage}
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}

                deleteHandler={deleteCategoryHandler}
                renameHandler={openRenamePopup}
              />
            ))}
          </ul>
        ) : (
          <Loader/>
        )}
      </div>

      <div className={s.right}>
        <div className={s.searchWrap}>
          <div className={s.titleWrap}>
            <h3 className={s.title}>
              {isDetailPage ? (
                <span className={s.primary}>Сопоставить подкатегории</span>
              ) : (
                <span className={s.primary}>Сопоставить категории</span>
              )}
              {activeCategory.category_name ? (
                <>
                  <span className={s.primary}> c </span>
                  <span>{activeCategory.category_name}</span>
                </>
              ) : (
                ''
              )}
            </h3>
            {categoryLvl && (
              <Select
                className={s.select}
                selectedItem={categoryLvl}
                handleSelect={setCategoryLvl}
                list={catLvls}
              />
            )}
          </div>
          <Search
            val={searchVal}
            className={s.search}
            setVal={setSearchVal}
            placeholder={'Поиск подкатегорий'}
          />
        </div>
        {activeCategory.api_category_id ? (
          <div className={s.subCategories}>
            {!subLoading ? (
              <ul
                className={
                  s.itemsList +
                  ' ' +
                  (subCategories.length === 0 ? s.empty : '')
                }
              >
                {subCategories.map((category, i) => (
                  <SubCategoryItem
                    key={i}
                    category={category}
                    isDetailPage={isDetailPage}
                    bindCategory={bindCategory}
                    activeCategory={activeCategory}
                  />
                ))}
                {subCategories.length === 0 && <p>Категорий не найдено</p>}
              </ul>
            ) : (
              <Loader />
            )}
          </div>
        ) : (
          <div className={s.subCategoriesEmpty}>
            <h2 className={s.title}>
              Выберите корневую категорию, чтобы начать устанавливать
              соответствие
            </h2>
          </div>
        )}
      </div>

      {activePopup === 'delete' && <Popup
        close={closePopup}
        windowClassName={s.deletePopup}
        className={s.deletePopupInner}
      >
        <p className={s.deletePopupText}>Чтобы удалить категорию {categoryPopupData.category_name}, вам необходимо удалить связь со всеми слейвами</p>
        <Link className={`btn dark ${s.deletePopupBtn}`} to={`/categories/${categoryPopupData._id}`}>Перейти к слейвам</Link>
      </Popup>}
      {activePopup === 'rename' && <Popup
        close={closePopup}
        windowClassName={s.renamePopup}
        className={s.renamePopupInner}
        title={`Изменение названия категории ${categoryPopupData?.category_name}`}
      >
        <Input
          label={'Название категории'}
          val={categoryPopupData.category_name}
          setVal={categoryPopupDataChangeName}
          placeholder={'Введите название'}
        />
        <div className={s.renamePopupBtns}>
          <button className={'btn light'} onClick={closePopup}>Отмена</button>
          <button className={'btn dark'} onClick={() => renameCategory(categoryPopupData._id, categoryPopupData.category_name)}>Сохранить</button>
        </div>
      </Popup>}
      {activePopup === 'create' && <Popup
        close={closePopup}
        windowClassName={s.createPopup}
        className={s.createPopupInner}
        title={'Создать новую категорию'}
      >
        <p className={s.createPopupParent}>Родитель: {categoryPopupData?.parent_category_name || 'нет'}</p>
        <Input
          label={'Название категории'}
          val={categoryPopupData.category_name}
          setVal={categoryPopupDataChangeName}
          placeholder={'Введите название'}
        />
        <div className={s.createPopupBtns}>
          <button className={'btn light'} onClick={closePopup}>Отмена</button>
          <button className={'btn dark'} onClick={() => createCategory(categoryPopupData)}>Сохранить</button>
        </div>
      </Popup>}
    </div>
  );
};

export default CategoryList;
