import s from "../Products/Products.module.scss";
import Input from "../../components/Input/Input";
import Popup from "../../components/Popup/Popup";
import checkIcon from "../../assets/icons/check";
import {useMemo, useState} from "react";
import {Link} from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const CategoryItem = ({isActive, showLevel, chooseHandler, _id, category_name, level}) => {
  return <div
    className={[
      s.changeCategoryPopupCategory,
      isActive ? s.changeCategoryPopupCategory_active : ''
    ].join(' ')}
    onClick={() => chooseHandler({_id, category_name})}
  >
    {showLevel && Array.from({length: level}).map(() => <i></i>)}
    <span>{category_name}</span>
    {isActive && checkIcon}
  </div>
}

const ChangeCategoryPopup = ({
  close,
  categories,
  changeCategoryHandler,
  data,
  categoryName,
  chooseHandler
}) => {
  const [searchVal, setSearchVal] = useState('');

  const filteredCategories = useMemo(() => {
    if (!searchVal || searchVal.length === 0) return categories;

    return categories.filter(({category_name}) => category_name.toLowerCase().includes(searchVal.toLowerCase()));
  }, [categories, searchVal])

  return <Popup
    title={data.step === 'initial' ? 'Выберите категорию, к которой хотите привязать товар' : ''}
    close={close}
    windowClassName={s.changeCategoryPopupWindow}
    className={[s.changeCategoryPopupIner, data.step === 'success' ? s.changeCategoryPopupInner_success : ''].join(' ')}
  >
    {
      data.step === 'initial' && <>
        <p className={s.changeCategoryPopupText}>После привязки товар будет отвязан от категории <span>{categoryName}</span></p>
        <Input
          placeholder={'Поиск категории'}
          val={searchVal}
          setVal={setSearchVal}
        />
        <div className={s.changeCategoryPopupList}>
          {!categories && <div className={s.changeCategoryPopupListLoader}><Loader/></div>}
          {
            filteredCategories && filteredCategories.map(categoryData =>
              <CategoryItem
                showLevel={!searchVal}
                chooseHandler={chooseHandler}
                isActive={data.new_category_id === categoryData._id}
                {...categoryData}
                key={categoryData._id}
              />)
          }
        </div>
        <div className={s.changeCategoryPopupBtns}>
          <button className={'btn light'} onClick={close}>Отмена</button>
          <button className={'btn dark'} disabled={!data.new_category_id} onClick={changeCategoryHandler}>Привязать</button>
        </div>
      </>
    }
    {
      data.step === 'success' && <>
        <p className={s.changeCategoryPopupSuccessText}>Товар успешно отвязан от категории <span>{categoryName}</span> и привязан к категории <span>{data.new_category_name}</span></p>
        <Link className={'btn dark'} to={`/categories/${data.new_category_id}`}>Перейти к категории {data.new_category_name}</Link>
      </>
    }
  </Popup>
}

export default ChangeCategoryPopup;